






























































import PacienteModule from "@/store/modules/paciente-module";
import { Component, Vue, PropSync } from "vue-property-decorator";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsString } from "@/utils/utils-string";
import { UtilsPaciente } from "@/utils/utils-paciente";
@Component({})
export default class PacienteLista extends Vue {
  //Se ejecuta nada mas entrar
  public buscar_paciente: string = "";
  public pacientes: paciente[] = [];
  public loadingtextfield: boolean = false;
  public totalpaci: number = 0;
  public textototal: string = "";
  public page: number = 1;
  public paginaslenght: number = 1;
  @PropSync("paciente") syncedpaciente!: paciente;

  public get datapacientes() {
    if (this.buscar_paciente === "") {
      this.totalpaci = PacienteModule.pacientes.length;
      this.textototal = "";

      return this.GenerarPaginacion(PacienteModule.pacientes);
    }

    this.loadingtextfield = true;
    this.pacientes = [];
    for (let i = 0; i < PacienteModule.pacientes.length; i++) {
      const paciente: paciente = PacienteModule.pacientes[i];
      if (
        UtilsString.eliminarDiacriticosEs(paciente.nombre)
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          ) ||
        UtilsString.eliminarDiacriticosEs(paciente.apellidos)
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          ) ||
        UtilsString.eliminarDiacriticosEs(paciente.email)
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          ) ||
        UtilsString.eliminarDiacriticosEs(
          UtilsString.ValueOf(paciente.telefono1)
        )
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          )
      ) {
        this.pacientes.push(paciente);
      }
    }
    this.totalpaci = this.pacientes.length;
    this.textototal = " en la busqueda ";
    this.loadingtextfield = false;

    return this.GenerarPaginacion(this.pacientes);
  }

  public get label_buscar() {
    if (this.buscar_paciente === "") {
      return this.$i18n.tc("buscar");
    }
    return "";
  }
  public getStyleBox(paciente: paciente) {
    let style = "";
    if (this.syncedpaciente === undefined) {
      return "";
    }
    if (this.syncedpaciente === null) {
      return "";
    }
    if (this.syncedpaciente.numero === paciente.numero) {
      return "box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);    background-color: #F9F9F9;border-color: orange;";
    }
  }
  public ImgPaciente(paciente: paciente) {
    return UtilsPaciente.getrutaImagen(paciente);
  }
  public seleccionar_paci(paciente: paciente) {
    this.syncedpaciente = paciente;
  }

  public GenerarPaginacion(pacientes: paciente[]): paciente[] {
    //Y ahora paginamos los resultados.
    // 1-1
    // 1-1
    //Es decir,dos filas y 4 clientes/pacientes
    this.paginaslenght = Math.ceil(this.totalpaci / 4);
    if (this.paginaslenght <= 0) {
      this.paginaslenght = 1;
    }

    let PacientesForRow: paciente[] = [];
    let Pages: any[] = [];
    for (let i = 0; i < pacientes.length; i++) {
      PacientesForRow.push(pacientes[i]);
      if (PacientesForRow.length === 4) {
        Pages.push(PacientesForRow);
        PacientesForRow = [];
      }
    }
    if (PacientesForRow.length !== 0) {
      Pages.push(PacientesForRow);
      PacientesForRow = [];
    }
    if (Pages[this.page - 1] == undefined) {
      return [];
    }
    if (Pages[this.page - 1] == null) {
      return [];
    }
    return Pages[this.page - 1];
  }
}
